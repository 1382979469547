import React, { Component } from 'react';
import { Navigate, useParams, useLocation } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import TextField from '@mui/material/TextField';

import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';

import Main from 'components/Main';

import Btn from 'components/Btn';

import Api from 'api/Api';

import Skeleton from '@mui/material/Skeleton';

import CustomerRenderAddress from 'pages/app/customers/blocks/CustomerRenderAddress';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Delete from '@mui/icons-material/Delete';

import LayoutHelper from 'helpers/LayoutHelper';

export function withRouter(Children){

    return(props) => {

        const location = useLocation();
        const params = {params: useParams()};

        const pathname = location.pathname;

        params['order_id'] = pathname.order_id;
        params['shipment_id'] = pathname.shipment_id;

        return <Children {...props} params={params} />
    }
}

class SalesShipmentView extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            redirect: false,

            logged_in: false,

            loading: true,

            submitting: false,

            error_message: '',
            success_message: '',

            type: 'preview',

            order: false,
            shipment: false,

            order_id: false,
            shipment_id: false,

            send_email: true,

            shipping_methods: [],

            tracking_rows: [],

            shipment_comment: ''
        }
    }

    componentDidMount = () => {
        
        let account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        let order_id = this.props.params.params.order_id;
        let shipment_id = this.props.params.params.shipment_id;
        
        if(account_token){

            this.setState({account_token: account_token})

            if(order_id){
            
                this.setState({order_id: order_id, type: 'preview'})
                this.loadOrder(account_token, order_id, false)
            }
        }
    }

    render(){

        if(this.state.redirect !== false){

            return <Navigate to={this.state.redirect} />
        }

        return (

            <Main
                active_page="sales"
                
                page="sales_shipment_view"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}

                title={`${this.state.type === 'preview' ? 'New Shipment' : ''}`}
            >
                {this.state.loading
                    ?
                        <Grid container spacing={1}>
                            <Grid item xs={12}>
                                <Skeleton variant="text" sx={{fontSize: '6rem', borderRadius: 3}} />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton variant="rectangular" width={'100%'} height={200} sx={{borderRadius: 3}} />
                            </Grid>
                            <Grid item xs={6}>
                                <Skeleton variant="rectangular" width={'100%'} height={200} sx={{borderRadius: 3}} />
                            </Grid>
                            <Grid item xs={12}>
                                <Skeleton variant="text" sx={{ fontSize: '6rem', borderRadius:3}} />
                                <Skeleton variant="text" sx={{ fontSize: '6rem', borderRadius: 3}} />
                            </Grid>
                        </Grid>
                    :
                        <div className=''>
                            
                            <Grid container spacing={0}>
                                <Grid item xs={3}>
                                    <Btn link={`/sales/orders/view/${this.state.order_id}`} startIcon={<ChevronLeft size={15} className='text-secondary-700' />} size="small" transparent={true} rounded={true}>
                                        Back to Order
                                    </Btn>
                                </Grid>
                                <Grid item xs={9}>
                                    <div className='justify-end'>
                                        
                                    </div>
                                </Grid>
                                <Grid item xs={12}>
                                    <Paper sx={{border: '1px solid rgba(0,0,0,.15)', padding: 3, marginTop: 2}} elevation={0} variant='outlined' square={true}>
                                        <Grid container spacing={2}>
                                            <Grid item xs={7} className='fs-12'>
                                                <Grid container spacing={1}>
                                                    <Grid item xs={6}>
                                                        <strong>Order Numebr:</strong>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span>#{this.state.order.order_number}</span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <strong>Order Date:</strong>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span>{this.state.order.order_placed_on_formatted}</span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <strong>Customer Name:</strong>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span>{this.state.order.customer_name}</span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <strong>Customer Email:</strong>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span>{this.state.order.customer_email}</span>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <strong>Customer Contact:</strong>
                                                    </Grid>
                                                    <Grid item xs={6}>
                                                        <span>{this.state.order.customer_contact}</span>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper elevation={0} sx={{borderTop: '1px solid rgba(0,0,0,.15)', paddingTop: 3, marginTop: 3, backgroundColor: 'transparent'}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Paper elevation={0} sx={{borderRight: '1px solid rgba(0,0,0,.15)', backgroundColor: 'transparent'}}>
                                                                <CustomerRenderAddress
                                                                    label="Billing Address"
                                                                    prefix="billing"
                                                                    address={this.state.order}
                                                                />
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={5}>
                                                            <CustomerRenderAddress
                                                                label="Shipping Address"
                                                                prefix="shipping"
                                                                address={this.state.order}
                                                            />
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper elevation={0} sx={{borderTop: '1px solid rgba(0,0,0,.15)', paddingTop: 3, marginTop: 3, backgroundColor: 'transparent'}}>
                                                    <Grid container spacing={2}>
                                                        <Grid item xs={5}>
                                                            <Paper elevation={0} sx={{borderRight: '1px solid rgba(0,0,0,.15)', backgroundColor: 'transparent'}}>

                                                                <div className='vertical'>
                                                                    <strong className='fs-14'>Payment Method</strong>

                                                                    <strong className='mt-10 fs-12 gr-7'>{this.state.order._payment_method.label}</strong>
                                                                </div>
                                                            </Paper>
                                                        </Grid>
                                                        <Grid item xs={7}>
                                                            <div className='vertical'>
                                                                <strong className='fs-14'>Shipping Method</strong>

                                                                <strong className='mt-10 fs-12 gr-7'>{this.state.order._shipping_method.label}</strong>

                                                                <strong className='mt-10 fs-13 gr-7'>Add Tracking Details</strong>

                                                                <Paper elevation={0} sx={{border: '1px solid rgba(0,0,0,.2)', backgroundColor: 'transparent', marginTop: 1}}>
                                                                    <TableContainer>
                                                                        <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                                                                            <TableHead>
                                                                                <TableRow>
                                                                                    <TableCell sx={{backgroundColor:'rgba(0,0,0,.15)', borderRight: '1px solid rgba(0,0,0,.2)', borderBottom: '1px solid rgba(0,0,0,.2)'}} width="20%">
                                                                                        <strong className='fs-12'>Carrier</strong>
                                                                                    </TableCell>
                                                                                    <TableCell sx={{backgroundColor:'rgba(0,0,0,.15)', borderRight: '1px solid rgba(0,0,0,.2)', borderBottom: '1px solid rgba(0,0,0,.2)'}} width="30%">
                                                                                        <strong className='fs-12'>Label</strong>
                                                                                    </TableCell>
                                                                                    <TableCell sx={{backgroundColor:'rgba(0,0,0,.15)', borderRight: '1px solid rgba(0,0,0,.2)', borderBottom: '1px solid rgba(0,0,0,.2)'}} width="40%">
                                                                                        <strong className='fs-12'>Tracking Number</strong>
                                                                                    </TableCell>
                                                                                    <TableCell sx={{backgroundColor:'rgba(0,0,0,.15)', borderRight: '1px solid rgba(0,0,0,.2)', borderBottom: '1px solid rgba(0,0,0,.2)'}} width="10%"></TableCell>
                                                                                </TableRow>
                                                                            </TableHead>
                                                                            <TableBody>
                                                                                    
                                                                                {this.state.tracking_rows.map((_tracking_row, index) => {

                                                                                    return (

                                                                                        <TableRow key={`tracking_row_${index}`}>
                                                                                            <TableCell sx={{backgroundColor:'rgba(0,0,0,.05)', padding: 1}}>
                                                                                                <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                                                                                                    <Select
                                                                                                        value={_tracking_row['carrier_code']}
                                                                                                        onChange={(e) => {

                                                                                                            let tracking_rows = this.state.tracking_rows;

                                                                                                            if(e.target.value !== 'custom'){

                                                                                                                let shipping_methods = this.state.shipping_methods;

                                                                                                                const __shipping_method = shipping_methods.find(row => row.code === e.target.value)

                                                                                                                if(__shipping_method){

                                                                                                                    tracking_rows[index]['carrier'] = __shipping_method.label
                                                                                                                }else{

                                                                                                                    tracking_rows[index]['carrier'] = '';
                                                                                                                }
                                                                                                            }else{

                                                                                                                tracking_rows[index]['carrier'] = '';
                                                                                                            }

                                                                                                            tracking_rows[index]['carrier_code'] = e.target.value
    
                                                                                                            this.setState({tracking_rows: tracking_rows})
                                                                                                        }}
                                                                                                    >
                                                                                                        {this.state.shipping_methods.map((_shipping_method, _index) => {

                                                                                                            return (
                                                                                                                <MenuItem value={_shipping_method.code} key={`shipping_method_${_index}_${index}`}>
                                                                                                                    {_shipping_method.label}
                                                                                                                </MenuItem>
                                                                                                            )
                                                                                                        })}

                                                                                                        <MenuItem value="custom">Custom Name</MenuItem>
                                                                                                    </Select>
                                                                                                </FormControl>
                                                                                            </TableCell>
                                                                                            <TableCell sx={{backgroundColor:'rgba(0,0,0,.05)', padding: 1}}>
                                                                                                <TextField
                                                                                                    placeholder='Enter Carrier Name'
                                                                                                    size="small"
                                                                                                    fullWidth
                                                                                                    value={_tracking_row['carrier']}
                                                                                                    onChange={(e) => {

                                                                                                        let tracking_rows = this.state.tracking_rows;

                                                                                                        tracking_rows[index]['carrier'] = e.target.value

                                                                                                        this.setState({tracking_rows: tracking_rows})
                                                                                                    }}
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell sx={{backgroundColor:'rgba(0,0,0,.05)', padding: 1}}>
                                                                                                <TextField
                                                                                                    placeholder='Enter Tracking Number'
                                                                                                    size="small"
                                                                                                    fullWidth
                                                                                                    value={_tracking_row['tracking_number']}
                                                                                                    onChange={(e) => {

                                                                                                        let tracking_rows = this.state.tracking_rows;

                                                                                                        tracking_rows[index]['tracking_number'] = e.target.value

                                                                                                        this.setState({tracking_rows: tracking_rows})
                                                                                                    }}
                                                                                                />
                                                                                            </TableCell>
                                                                                            <TableCell sx={{backgroundColor:'rgba(0,0,0,.05)', padding: 1}}>
                                                                                                <Btn icon={true} size="small" confirm confirm_message="Do you really want to remove this entry?" onClick={() => {

                                                                                                    let tracking_rows = this.state.tracking_rows;

                                                                                                    tracking_rows.splice(index, 1);

                                                                                                    this.setState({tracking_rows: tracking_rows})
                                                                                                }}>
                                                                                                    <Delete size={16} style={{fontSize: 18}} className='c-red' />
                                                                                                </Btn>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                    )
                                                                                })}

                                                                                <TableRow>
                                                                                    <TableCell sx={{backgroundColor:'rgba(0,0,0,.05)', padding: 2}} colSpan={4}>
                                                                                        <div className='align-center'>
                                                                                            <Btn size="small" color="secondary" variant="contained" onClick={() => {

                                                                                                let tracking_rows = this.state.tracking_rows;

                                                                                                tracking_rows.push({carrier_code: '', carrier: '', tracking_number: ''})

                                                                                                this.setState({tracking_rows: tracking_rows})
                                                                                            }}>Add Tracking Details</Btn>
                                                                                        </div>
                                                                                    </TableCell>
                                                                                </TableRow>
                                                                            </TableBody>
                                                                        </Table>
                                                                    </TableContainer>
                                                                </Paper>
                                                            </div>
                                                        </Grid>
                                                    </Grid>
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <Paper elevation={0} sx={{borderTop: '1px solid rgba(0,0,0,.15)', paddingTop: 3, marginTop: 3, backgroundColor: 'transparent'}}>

                                                    {this.state.order !== false && this.state.order.hasOwnProperty('order_items') &&
                                                    
                                                        <TableContainer>
                                                            <Table size="small">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>
                                                                            <strong className='fs-12'>SKU</strong>
                                                                        </TableCell>
                                                                        <TableCell>
                                                                            <strong className='fs-12'>Product Name</strong>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <strong className='fs-12'>Ordered Qty</strong>
                                                                        </TableCell>
                                                                        <TableCell align="center">
                                                                            <strong className='fs-12'>Ship Qty</strong>
                                                                        </TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                
                                                                    {this.state.order.order_items.map((row, index) => {
                                                                    
                                                                        return (
                                                                            <TableRow
                                                                                key={`cart_item_row_${index}`}
                                                                                className='has-hoverable-action'
                                                                            >
                                                                                <TableCell>
                                                                                    <span className='fs-12'>{row.type === 'variable' ? row.variant_sku : row.sku}</span>
                                                                                </TableCell>
                                                                                <TableCell>
                                                                                    <span className='fs-12'>{row.name}</span>

                                                                                    {this.renderVariants(row)}
                                                                                </TableCell>
                                                                                <TableCell align='center'>
                                                                                    {row.qty}
                                                                                </TableCell>
                                                                                <TableCell align='center'>
                                                                                    {row.qty}
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        )
                                                                    })}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                    }
                                                </Paper>
                                            </Grid>
                                            <Grid item xs={6}></Grid>
                                            <Grid item xs={6}>
                                                <Paper elevation={0} sx={{backgroundColor: 'transparent', paddingRight: 3}}>

                                                    <FormControl fullWidth>
                                                        <TextField
                                                            size="small"
                                                            label=""
                                                            multiline
                                                            rows={4}
                                                            placeholder='Shipment Note'
                                                            onChange={(e) => {

                                                                this.setState({shipment_comment: e.target.value})
                                                            }}
                                                            value={this.state.shipment_comment}
                                                        />
                                                    </FormControl>

                                                    <div className='align-end vertical mt-10'>

                                                        <FormGroup>
                                                            <FormControlLabel
                                                                control={
                                                                    <Checkbox
                                                                        checked={this.state.send_email}
                                                                        onChange={(e) => {

                                                                            this.setState({send_email: e.target.checked})
                                                                        }}
                                                                    />
                                                                }
                                                                label="Email Copy of Shipment to Customer"
                                                            />
                                                        </FormGroup>

                                                        <Btn variant="contained" color="secondary" loading={this.state.submitting} onClick={() => {

                                                            this.generateShipment()
                                                        }}>
                                                            Submit Shipment
                                                        </Btn>
                                                    </div>
                                                </Paper>
                                            </Grid>
                                        </Grid>
                                    </Paper>
                                </Grid>
                            </Grid>
                        </div>
                }
            </Main>
        )
    }
    
    loadOrder = (account_token, row_id, load) => {

        const formData = new FormData();
        formData.append('account_token', account_token);
        formData.append('row_id', row_id);

        var self = this;

        if(load){
        
            this.setState({loading: true});
        }

        Api.post('backend/cart/shipment/init', formData, function(data){

            if(data.status){

                self.setState({order: data.order, shipping_methods: data.methods}, () => {

                    self.setState({loading: false});
                });
            }
        });
    }

    generateShipment = () => {

        const formData = new FormData();
        formData.append('account_token', this.state.account_token);
        formData.append('row_id', this.state.order.row_id);

        formData.append('email', this.state.send_email === true ? 1 : 0);

        let tracking_rows = this.state.tracking_rows;

        formData.append('tracking', JSON.stringify(tracking_rows));

        formData.append('shipment_note', this.state.shipment_comment);

        var self = this;

        this.setState({submitting: true});

        Api.post('backend/cart/shipment/generate', formData, function(data){

            if(data.status){

                localStorage.setItem('flash_success_message', data.message);

                self.setState({redirect: `/sales/orders/view/${self.state.order.row_id}`})
            }else{

                this.setState({submitting: false});

                LayoutHelper.addErrorMessage(self, data.message);
            }
        });
    }

    renderVariants = (item) => {

        let variants = [];

        if(item.hasOwnProperty('variants')){

            item.variants.forEach((_variant, index) => {

                variants.push(
                    <div style={{lineHeight: 1}} key={`variant_index_${index}_${item.row_id}`}>
                        <strong className='fs-12'>{_variant.attribute_name}: </strong>
                        <span className='fs-12'>{_variant.option_label}</span>
                    </div>
                )
            })
        }
        
        return variants;
    }
}

export default withRouter(SalesShipmentView);