import React, { Component } from 'react';
import { Navigate, useParams, useLocation } from "react-router-dom";

import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import DataTable from 'components/wd/data_table/DataTable';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';

import Chip from '@mui/material/Chip';

import Main from 'components/Main';

import Btn from 'components/Btn';

import Api from 'api/Api';

import Skeleton from '@mui/material/Skeleton';

import CustomerRenderAddress from 'pages/app/customers/blocks/CustomerRenderAddress';
import SalesOrdersTotals from './SalesOrdersTotals';

import ChevronLeft from '@mui/icons-material/ChevronLeft';
import Download from '@mui/icons-material/Download';
import Edit from '@mui/icons-material/Edit';
import ArrowBackIosNew from '@mui/icons-material/ArrowBackIosNew';
import ListAlt from '@mui/icons-material/ListAlt';
import ReceiptLong from '@mui/icons-material/ReceiptLong';
import LocalShipping from '@mui/icons-material/LocalShipping';

import LayoutHelper from 'helpers/LayoutHelper';

import Price from 'components/Price';

export function withRouter(Children){

    return(props) => {

        const location = useLocation();
        const params = {params: useParams()};

        const pathname = location.pathname;

        params['row_id'] = pathname.row_id;

        return <Children {...props} params={params} />
    }
}

class SalesOrdersView extends Component {

    constructor(props) {
        super();
        this.state = {

            account_token: false,
            user: false,

            redirect: false,

            logged_in: false,

            loading: true,

            error_message: '',
            success_message: '',

            order: false,
            
            row_id: false,

            order_process: [],
            process_loading: false,

            active_tab: 'view',

            order_comment: '',

            order_actions: [],

            order_status_updating: false,

            capturing_payment: false
        }
    }

    componentDidMount = () => {

        let account_token = localStorage.getItem(process.env.REACT_APP_ACCOUNT_TOKEN);
        let row_id = this.props.params.params.row_id;
        
        if(account_token && row_id){
            
            this.setState({account_token: account_token, row_id: row_id})
            this.loadOrder(account_token, row_id, false)
            this.orderProcess(account_token, row_id)
        }
    }

    render(){

        if(this.state.redirect !== false){

            return <Navigate to={this.state.redirect} />
        }

        return (

            <Main
                active_page="sales"
                
                page="sales_orders_view"
                
                error_message={this.state.error_message}
                success_message={this.state.success_message}

                title={`Order View ${this.state.order !== false ? `#${this.state.order.order_number}` : ''}`}
            >

                <div className='form-wrapper full'>
                    <div className='form-container'>
                        <div className='form-header'>
                            <div className='form-header-action'>
                            
                                <Btn color="primary" size="small" startIcon={<ArrowBackIosNew />} style={{borderRadius: 0, width: '100%', height: '100%'}} link="/sales/orders">
                                    Back
                                </Btn>
                            </div>
                            <div className='form-header-block space-between' style={{flex: 1}}>
                                <div>
                                    <h1>{`Order View ${this.state.order !== false ? `#${this.state.order.order_number}` : ''}`}</h1>
                                    <strong className="subtitle">{this.state.order.order_placed_on_formatted}</strong>
                                </div>
                                <div>
                                    {this.state.order !== false &&

                                        (this.state.order._payment_method.code === 'authorize_net') &&
                                                                        
                                            <div>

                                                {(this.state.order.payment_status === 'pending' || this.state.order.payment_status === '') &&
                                                
                                                    <Btn
                                                        loading={this.state.capturing_payment}
                                                        onClick={() => {

                                                            this.approvePayment()
                                                        }}
                                                    >
                                                        Capture Payment
                                                    </Btn>
                                                }

                                                {(this.state.order.payment_status === 'approved') &&
                                                
                                                    <div className='vertical'>
                                                        <span className='fs-12 fw-bold gr-6'>Payment captured</span>

                                                        {this.state.order._payment_method_response.hasOwnProperty('date_formatted') &&

                                                            <span className='fs-12 fw-bold'>{this.state.order._payment_method_response.date_formatted}</span>
                                                        }
                                                    </div>
                                                }
                                            </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className='form-body'>
                            <div className='form-tabbed'>
                                <div className='form-tabs'>
                                    <Tabs
                                        value={this.state.active_tab}
                                        onChange={(e, tab_num) => {

                                            this.setState({active_tab: tab_num})
                                        }}
                                        orientation="vertical"
                                    >

                                        <Tab icon={<ListAlt />} iconPosition='top' label="Order View" value="view" />
                                        <Tab icon={<ReceiptLong />} iconPosition='top' label="Invoices" value="invoices" />
                                        <Tab icon={<LocalShipping />} iconPosition='top' label="Shipments" value="shipments" />
                                    </Tabs>
                                </div>
                                <div className='form-tab-fields'>
                                    <div role="tabpanel" hidden={this.state.active_tab !== 'view'} value="view" index="view">

                                        <div className='form-body-wrapper'>
                                        
                                            {this.state.loading
                                                ?
                                                    <Grid container spacing={1}>
                                                        <Grid item xs={12}>
                                                            <Skeleton variant="text" sx={{fontSize: '6rem', borderRadius: 3}} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Skeleton variant="rectangular" width={'100%'} height={200} sx={{borderRadius: 3}} />
                                                        </Grid>
                                                        <Grid item xs={6}>
                                                            <Skeleton variant="rectangular" width={'100%'} height={200} sx={{borderRadius: 3}} />
                                                        </Grid>
                                                        <Grid item xs={12}>
                                                            <Skeleton variant="text" sx={{ fontSize: '6rem', borderRadius:3}} />
                                                            <Skeleton variant="text" sx={{ fontSize: '6rem', borderRadius: 3}} />
                                                        </Grid>
                                                    </Grid>
                                                :
                                                    <div className='mt-10'>
                                                        
                                                        <Grid container spacing={3}>
                                                            {/* <Grid item xs={3}>
                                                                <Btn link="/sales/orders" startIcon={<ChevronLeft size={15} className='text-secondary-700' />} size="small" transparent={true} rounded={true}>
                                                                    Back to Orders
                                                                </Btn>
                                                            </Grid>
                                                            <Grid item xs={9}>
                                                                <div className='justify-end'>

                                                                    {(this.state.order._payment_method.code === 'authorize_net') &&
                                                                    
                                                                        <div>

                                                                            {(this.state.order.payment_status === 'pending' || this.state.order.payment_status === '') &&
                                                                            
                                                                                <Btn
                                                                                    loading={this.state.capturing_payment}
                                                                                    onClick={() => {

                                                                                        this.approvePayment()
                                                                                    }}
                                                                                >
                                                                                    Capture Payment
                                                                                </Btn>
                                                                            }

                                                                            {(this.state.order.payment_status === 'approved') &&
                                                                            
                                                                                <div className='vertical'>
                                                                                    <span className='fs-12 fw-bold gr-6'>Payment captured</span>

                                                                                    {this.state.order._payment_method_response.hasOwnProperty('date_formatted') &&

                                                                                        <span className='fs-12 fw-bold'>{this.state.order._payment_method_response.date_formatted}</span>
                                                                                    }
                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    }

                                                                    <div className='ml-20'>

                                                                        {(this.state.order.invoice_generated == '1') &&
                                                                        
                                                                            <a target='_blank' href={`https://phpstack-1150204-4002913.cloudwaysapps.com/sales/invoice/download/${this.state.order.row_id}/${this.state.account_token}`}>
                                                                                <Btn color="secondary" variant="contained">
                                                                                    Download Invoice
                                                                                </Btn>
                                                                            </a>
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </Grid> */}
                                                            <Grid item xs={8}>
                                                                <Paper sx={{border: '1px solid rgba(0,0,0,.15)', backgroundColor: 'rgba(0,0,0,.02)', padding: 3, marginTop: 2}} elevation={0} variant='outlined' square={true}>
                                                                    <Grid container spacing={2}>
                                                                        <Grid item xs={7} className='fs-12'>
                                                                            <Grid container spacing={1}>
                                                                                <Grid item xs={6}>
                                                                                    <strong>Order Number:</strong>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <span>#{this.state.order.order_number}</span>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <strong>Order Date:</strong>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <span>{this.state.order.order_placed_on_formatted}</span>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <strong>Customer Name:</strong>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <span>{this.state.order.customer_name}</span>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <strong>Customer Email:</strong>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <span>{this.state.order.customer_email}</span>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <strong>Customer Contact:</strong>
                                                                                </Grid>
                                                                                <Grid item xs={6}>
                                                                                    <span>{this.state.order.customer_contact}</span>
                                                                                </Grid>
                                                                            </Grid>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Paper elevation={0} sx={{borderTop: '1px solid rgba(0,0,0,.15)', paddingTop: 3, marginTop: 3, backgroundColor: 'transparent'}}>
                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={5}>
                                                                                        <Paper elevation={0} sx={{borderRight: '1px solid rgba(0,0,0,.15)', backgroundColor: 'transparent'}}>
                                                                                            
                                                                                            <CustomerRenderAddress
                                                                                                label="Billing Address"
                                                                                                prefix="billing"
                                                                                                address={this.state.order}
                                                                                                after_label={<strong>{this.state.order.customer_name}</strong>}
                                                                                            />
                                                                                        </Paper>
                                                                                    </Grid>
                                                                                    <Grid item xs={5}>

                                                                                        <CustomerRenderAddress
                                                                                            label="Shipping Address"
                                                                                            prefix="shipping"
                                                                                            address={this.state.order}
                                                                                            after_label={<strong>{this.state.order.shipping_name}</strong>}
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Paper>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Paper elevation={0} sx={{borderTop: '1px solid rgba(0,0,0,.15)', paddingTop: 3, marginTop: 3, backgroundColor: 'transparent'}}>
                                                                                <Grid container spacing={2}>
                                                                                    <Grid item xs={5}>
                                                                                        <Paper elevation={0} sx={{borderRight: '1px solid rgba(0,0,0,.15)', backgroundColor: 'transparent'}}>

                                                                                            <div className='vertical'>
                                                                                                <strong className='fs-14'>Payment Method</strong>

                                                                                                <strong className='mt-10 fs-12 gr-7'>{this.state.order._payment_method.label}</strong>
                                                                                            </div>
                                                                                        </Paper>
                                                                                    </Grid>
                                                                                    <Grid item xs={5}>
                                                                                        <div className='vertical'>
                                                                                            <strong className='fs-14'>Shipping Method</strong>

                                                                                            <strong className='mt-10 fs-12 gr-7'>{this.state.order._shipping_method.label}</strong>

                                                                                            {this.state.order.shipping_method_service_label !== '' &&
                                                                                            
                                                                                                <span className='fs-12 gr-9'>({this.state.order.shipping_method_service_label})</span>
                                                                                            }
                                                                                        </div>
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </Paper>
                                                                        </Grid>
                                                                        <Grid item xs={12}>
                                                                            <Paper elevation={0} sx={{borderTop: '1px solid rgba(0,0,0,.15)', paddingTop: 3, marginTop: 3, backgroundColor: 'transparent'}}>

                                                                                {this.state.order !== false && this.state.order.hasOwnProperty('order_items') &&
                                                                                
                                                                                    <TableContainer>
                                                                                        <Table size="small">
                                                                                            <TableHead>
                                                                                                <TableRow>
                                                                                                    <TableCell>
                                                                                                        <strong className='fs-12'>Item</strong>
                                                                                                    </TableCell>
                                                                                                    <TableCell align='right'>
                                                                                                        <strong className='fs-12'>Price</strong>
                                                                                                    </TableCell>
                                                                                                    <TableCell align='right'>
                                                                                                        <strong className='fs-12'>IGST</strong>
                                                                                                    </TableCell>
                                                                                                    <TableCell align='right'>
                                                                                                        <strong className='fs-12'>SGST</strong>
                                                                                                    </TableCell>
                                                                                                    <TableCell align='right'>
                                                                                                        <strong className='fs-12'>CGST</strong>
                                                                                                    </TableCell>
                                                                                                    <TableCell align="center">
                                                                                                        <strong className='fs-12'>Qty</strong>
                                                                                                    </TableCell>
                                                                                                    <TableCell align='right'>
                                                                                                        <strong className='fs-12'>Total</strong>
                                                                                                    </TableCell>
                                                                                                </TableRow>
                                                                                            </TableHead>
                                                                                            <TableBody>
                                                                                            
                                                                                                {this.state.order.order_items.map((row, index) => {
                                                                                                
                                                                                                    return (
                                                                                                        <TableRow
                                                                                                            key={`cart_item_row_${index}`}
                                                                                                            className='has-hoverable-action'
                                                                                                        >
                                                                                                            <TableCell>
                                                                                                                <div className='justify-start'>
                                                                                                                    <div>
                                                                                                                        {row.image_url !== '' &&
                                                                                                                        
                                                                                                                            <img src={row.image_url} style={{maxWidth: 60, maxHeight: 60}} />
                                                                                                                        }
                                                                                                                    </div>
                                                                                                                    <div className='vertical'>
                                                                                                                        <strong className='fs-12'>{row.name}</strong>
                                                                                                                        <span className='fs-12 mb-5'>{row.type === 'variable' ? row.variant_sku : row.sku}</span>

                                                                                                                        {this.renderVariants(row)}
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </TableCell>
                                                                                                            <TableCell align='right'>
                                                                                                                <div className='justify-start'>
                                                                                                                    <Price
                                                                                                                        price={row.final_price}
                                                                                                                    />
                                                                                                                </div>
                                                                                                            </TableCell>
                                                                                                            <TableCell align='right'>
                                                                                                                {this.renderTax(row, 'igst')}
                                                                                                            </TableCell>
                                                                                                            <TableCell align='right'>
                                                                                                                {this.renderTax(row, 'sgst')}
                                                                                                            </TableCell>
                                                                                                            <TableCell align='right'>
                                                                                                                {this.renderTax(row, 'cgst')}
                                                                                                            </TableCell>
                                                                                                            <TableCell align='center'>
                                                                                                                {row.qty}
                                                                                                            </TableCell>
                                                                                                            <TableCell align='right'>
                                                                                                                <Price
                                                                                                                    price={row.row_total}
                                                                                                                />
                                                                                                            </TableCell>
                                                                                                        </TableRow>
                                                                                                    )
                                                                                                })}
                                                                                            </TableBody>
                                                                                        </Table>
                                                                                    </TableContainer>
                                                                                }
                                                                            </Paper>
                                                                        </Grid>
                                                                        <Grid item xs={6}></Grid>
                                                                        <Grid item xs={6}>
                                                                            <Paper elevation={0} sx={{backgroundColor: 'transparent', paddingRight: 3}}>
                                                                                <SalesOrdersTotals
                                                                                    totals={this.state.order.totals}
                                                                                />
                                                                            </Paper>
                                                                        </Grid>
                                                                    </Grid>
                                                                </Paper>
                                                            </Grid>
                                                            <Grid item xs={4}>

                                                                <Paper elevation={0} sx={{marginTop: 2, padding: 2, border: '1px solid rgba(0,0,0,.15)', backgroundColor: 'rgba(0,0,0,.02)'}}>

                                                                    <ul className='tree-view fs-12'>

                                                                        <li>
                                                                            <div className='vertical'>
                                                                                <strong>Order Placed On:</strong>
                                                                                <span>{this.state.order.order_placed_on_formatted}</span>
                                                                            </div>
                                                                        </li>

                                                                        {this.state.order_process.map((_order_process, index) => {

                                                                            return (
                                                                                <li key={`process_${index}`}>
                                                                                    <div className='vertical'>
                                                                                        {((index + 1) === this.state.order_process.length)
                                                                                            ?
                                                                                                <strong>Current Status:</strong>
                                                                                            :
                                                                                                <strong>Status:</strong>
                                                                                        }

                                                                                        <div className='space-between'>
                                                                                            <strong className='c-blue'>{_order_process.status_label}</strong>

                                                                                            <span>{_order_process.added_on_formatted}</span>
                                                                                        </div>
                                                                                    </div>

                                                                                    {_order_process.comment !== '' &&
                                                                                        
                                                                                        <div className='justify-start gr-5'>
                                                                                            <Edit style={{fontSize: 12}} />
                                                                                            <span>{_order_process.comment}</span>
                                                                                        </div>        
                                                                                    }
                                                                                </li>
                                                                            )
                                                                        })}
                                                                    </ul>

                                                                    {this.state.order_actions.length > 0 &&
                                                                        
                                                                        <div className='mt-10 vertical fs-12'>

                                                                            <strong>Update Order Status:</strong>

                                                                            <div className='mt-10'>
                                                                                <FormControl fullWidth>
                                                                                    <TextField
                                                                                        size="small"
                                                                                        label=""
                                                                                        multiline
                                                                                        rows={4}
                                                                                        placeholder='Order Note'
                                                                                        helperText="Add comment to order"
                                                                                        onChange={(e) => {

                                                                                            this.setState({order_comment: e.target.value})
                                                                                        }}
                                                                                        value={this.state.order_comment}
                                                                                    />
                                                                                </FormControl>
                                                                            </div>

                                                                            <div className='mt-10 space-between'>
                                                                                {this.state.order_actions.map((_order_action, index) => {

                                                                                    if(_order_action.key === 'processing'){

                                                                                        return (
                                                                                            <Btn key={`action_${_order_action.key}`} color="secondary" variant="contained" to={`/sales/invoice/preview/${this.state.order.row_id}`}>
                                                                                                Invoice
                                                                                            </Btn>
                                                                                        )

                                                                                    }else if(_order_action.key === 'shipped'){

                                                                                        return (
                                                                                            <Btn key={`action_${_order_action.key}`} color="secondary" variant="contained" to={`/sales/shipment/preview/${this.state.order.row_id}`}>
                                                                                                Ship
                                                                                            </Btn>
                                                                                        )
                                                                                    }else{
                                                                                    
                                                                                        return (
                                                                                            <Btn key={`action_${_order_action.key}`} size="small" color="secondary" variant="contained" confirm={true} confirm_message={`Do you really want to mark the order status "${_order_action.label}"`} loading={this.state.order_status_updating === _order_action.key ? true : false} onClick={() => {

                                                                                                this.updateOrder(_order_action.key)
                                                                                            }}>
                                                                                                {_order_action.label}
                                                                                            </Btn>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    }

                                                                    {this.state.order.order_status === 'complete' &&

                                                                        <div className='fs-12 align-center'>
                                                                            <p>Order marked as <strong className='c-green'>{this.state.order.status_label}</strong></p>
                                                                        </div>
                                                                    }

                                                                    {this.state.order.order_status === 'cancel' &&

                                                                        <div className='fs-12 align-center'>
                                                                            <p>Order marked as <strong className='c-red'>{this.state.order.status_label}</strong></p>
                                                                        </div>
                                                                    }
                                                                </Paper>
                                                            </Grid>
                                                        </Grid>
                                                    </div>
                                            }
                                        </div>
                                    </div>

                                    <div role="tabpanel" hidden={this.state.active_tab !== 'invoices'} value="invoices" index="invoices">
                                        
                                        <div className='form-body-wrapper'>

                                            <h4 className="title">Invoices</h4>

                                            {this.state.active_tab === 'invoices' &&

                                                <DataTable
                                                    index="sales_orders_invoices_list"
                                                    label="Invoices"

                                                    filter_params={[
                                                        {key: 'order_id', value: this.state.order.row_id}
                                                    ]}

                                                    columns={[
                                                        {name: 'Invoice Number', column: 'invoice_number', sortable: true},
                                                        {name: 'Invoice Date', column: 'added_on', sortable: true, hide_search: true},
                                                        // {name: 'Status', column: 'order_status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.status_options},
                                                    ]}

                                                    row_actions={(row, row_index) => {

                                                        return (

                                                            <div className="hoverable-action">
                                                                <div className="align-start">

                                                                    <a target='_blank' href={`${Api.url}sales/invoice/download/${this.state.order.row_id}/${this.state.account_token}`}>
                                                                        <Btn color="secondary" startIcon={<Download style={{fontSize: 15}} />}>
                                                                            Download Invoice
                                                                        </Btn>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    }}

                                                    default_sort_by="added_on"

                                                    api_url="sales/orders/invoices/listing"

                                                    account_token={this.state.account_token}
                                                    
                                                    row_id="row_id"
                                                />
                                            }
                                        </div>
                                    </div>

                                    <div role="tabpanel" hidden={this.state.active_tab !== 'shipments'} value="shipments" index="shipments">
                                        
                                        <div className='form-body-wrapper'>

                                            <h4 className="title">Shipments</h4>

                                            {this.state.active_tab === 'shipments' &&

                                                <DataTable
                                                    index="sales_orders_shipments_list"
                                                    label="Shipments"

                                                    filter_params={[
                                                        {key: 'order_id', value: this.state.order.row_id}
                                                    ]}

                                                    columns={[
                                                        {name: 'Shipment Number', column: 'shipment_number', sortable: true},
                                                        {name: 'Shipment Date', column: 'added_on', sortable: true, hide_search: true},
                                                        // {name: 'Status', column: 'order_status', sortable: true, search_type: 'match', search_input: 'dropdown', search_data: this.state.status_options},
                                                    ]}

                                                    row_actions={(row, row_index) => {

                                                        return (

                                                            <div className="hoverable-action">
                                                                <div className="align-start">

                                                                    <a target='_blank' href={`${Api.url}sales/shipment/download/${row.row_id}/${this.state.account_token}`}>
                                                                        <Btn color="secondary" startIcon={<Download style={{fontSize: 15}} />}>
                                                                            Download Shipment
                                                                        </Btn>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        )
                                                    }}

                                                    default_sort_by="added_on"

                                                    api_url="sales/orders/shipment/listing"

                                                    account_token={this.state.account_token}
                                                    
                                                    row_id="row_id"
                                                />
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Main>
        )
    }
    
    init = (account_token) => {

        const formData = new FormData();
        formData.append('account_token', account_token);

        var self = this;
        Api.post('sales/orders/init', formData, function(data){

            if(data.status){

                self.setState({
                    payment_methods: data.payment_methods,
                    shipping_methods: data.shipping_methods,
                    status_options: data.status_options
                });
            }
        });
    }

    loadOrder = (account_token, row_id, load) => {

        const formData = new FormData();
        formData.append('account_token', account_token);
        formData.append('row_id', row_id);

        var self = this;

        if(load){
        
            this.setState({loading: true});
        }

        Api.post('backend/cart/order/load', formData, function(data){

            if(data.status){

                self.setState({order: data.order}, () => {

                    self.setState({loading: false});
                });
            }
        });
    }

    orderProcess = (account_token, row_id) => {

        const formData = new FormData();
        formData.append('account_token', account_token);
        formData.append('row_id', row_id);

        var self = this;

        this.setState({process_loading: true});

        Api.post('backend/cart/order/process/load', formData, function(data){

            if(data.status){

                self.setState({order_process: data.order_process, order_actions: data.actions}, () => {

                    self.setState({process_loading: false});
                });
            }
        });
    }

    updateOrder = (status) => {

        const formData = new FormData();
        formData.append('account_token', this.state.account_token);
        formData.append('row_id', this.state.order.row_id);
        formData.append('status', status);
        formData.append('comment', this.state.order_comment);

        var self = this;

        this.setState({order_status_updating: status});

        Api.post('backend/cart/order/process/update', formData, function(data){

            if(data.status){

                self.setState({order_process: data.order_process, order_actions: data.actions}, () => {

                    self.setState({order_status_updating: false, order_comment: ''});
                    self.loadOrder(self.state.account_token, self.state.order.row_id, false)
                });
            }
        });
    }

    approvePayment = () => {

        const formData = new FormData();
        formData.append('account_token', this.state.account_token);
        formData.append('row_id', this.state.order.row_id);

        var self = this;

        this.setState({capturing_payment: true});

        Api.post('backend/cart/order/payment/authorize_net/capture', formData, function(data){

            self.setState({capturing_payment: false})

            if(data.status){

                self.setState({order: data.order});

                LayoutHelper.addSuccessMessage(self, data.message)
            }else{

                LayoutHelper.addErrorMessage(self, data.message)
            }
        });
    }

    renderVariants = (item) => {

        let variants = [];

        if(item.hasOwnProperty('variants')){

            item.variants.forEach((_variant, index) => {

                variants.push(
                    <div style={{lineHeight: 1}} key={`variant_index_${index}_${item.row_id}`}>
                        <strong className='fs-12'>{_variant.attribute_name}: </strong>
                        <span className='fs-12'>{_variant.option_label}</span>
                    </div>
                )
            })
        }
        
        return variants;
    }

    renderTax = (item, key) => {

        if(item.hasOwnProperty('item_taxes')){

            if(item.item_taxes.hasOwnProperty(key)){

                const tax_row = item.item_taxes[key];

                if(tax_row){

                    return <div>
                                <span className='text-xs text-slate-800'>
                                    <Price
                                        price={tax_row.item_tax}
                                        icon_size={12}
                                    />
                                </span>
                                {/* <span>({tax_row.calculated_tax})</span> */}
                            </div>
                }
            }
        }
    }
}

export default withRouter(SalesOrdersView);